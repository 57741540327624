(function () {
    'use strict';

    angular.module('informaApp')
        .component('barChartElement', {
            templateUrl: 'components/summary-view/summary-elements/elements/bar-chart-element/template.html',
            controller: BarChartElement,
            bindings: {
                data: '<',
                settings: '<',
                onStartRedraw: '<',
                onReady: '<'
            }
        });

    function BarChartElement(BarChartMapper, ObjectPropertyReader, MetricsHelper) {
        this.onRedraw = () => {
            this.onStartRedraw();
            this.onReady();
        };

        this.$onChanges = () => {
            this.chartSource = this.data ? [BarChartMapper.map(this.data)] : [];

            this.hiddenPhases = ObjectPropertyReader.getPropertyOrDefault(this.settings, x => x.hiddenPhases, []);

            this.chartSettings = {
                hideScroll: true,
                hideXAxis: true,
                showNumbers: ObjectPropertyReader.getPropertyOrDefault(this.settings, x => x.showNumbers)
            };
        };
    }
})();